import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TokenStorageService } from './token-storage.service';

const AUTH_API = '/bck/auth/';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,private router: Router,private token: TokenStorageService) { }

  login(credentials): Observable<any> {
    //when using https transfers work only with rimecs.com not the ip
    //  return this.http.post("http://localhost:9099"+AUTH_API + 'signin', {
    return this.http.post("https://rimecs.com/backd"+AUTH_API + 'signin', {
      username: credentials.username,
      password: credentials.password
    }, httpOptions);
  }
  register(user): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    }, httpOptions);
  }
  async routeOnLogin() {
  /*const user = this.afAuth.auth.currentUser;
    const token = await user.getIdTokenResult();
    localStorage.setItem("cuid",user.uid);*/
     
     if ( this.token.getUser().authProfile>0 && 
     (this.token.getUser().authProfile==1 || this.token.getUser().authProfile==2)) {
      this.router.navigate(['/conteneurs']);
    } else if(this.token.getUser().authProfile>0 && 
    this.token.getUser().authProfile==3){
    //  this.router.navigate([`/profile/${user.uid}`]);
      this.router.navigate([`/marchandise`]);
    } 
  }
}