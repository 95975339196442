// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase:  {
    apiKey: "AIzaSyCgpYuVJgwhlbG988vO6lBVEkiWFgglOuI",
    authDomain: "etsloulyd.firebaseapp.com",
    databaseURL: "https://etsloulyd.firebaseio.com",
    projectId: "etsloulyd",
    storageBucket: "etsloulyd.appspot.com",
    messagingSenderId: "765844001041",
    appId: "1:765844001041:web:1f4b2ae455e9ffb2973cb0",
    measurementId: "G-T9XXHS8B82"
  }
};
