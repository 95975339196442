import {LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
//import { HttpModule } from '@angular/http';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent }   from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
//import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
//custom
import { CommonModule } from '@angular/common';
import { ToastrModule } from "ngx-toastr";
import { environment } from '../environments/environment'; 
import { NotificationsComponent } from './components/notifications/notifications.component'; 
import {LoginComponent } from './pages/login/login.component';
 
import { ServiceWorkerModule } from '@angular/service-worker';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, NativeDateModule } from '@angular/material/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import localeFr from '@angular/common/locales/fr';  
import localeAr from '@angular/common/locales/ar';  
import { MatIconModule } from '@angular/material/icon';  
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { authInterceptorProviders } from './pages/_helpers/auth.interceptor'; 
import {MatSelectModule} from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { PagesModule } from './pages/pages.module'; 
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeAr, 'ar');
@NgModule({
    imports:      [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes,{
          useHash: true
        }),
        NgbModule, 
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedPluginModule, 
        ToastrModule.forRoot(),     
        MatStepperModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        MatButtonModule,
        MatDatepickerModule, NativeDateModule,
        MatIconModule, 
        MatProgressBarModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }) ,
        BrowserModule,
        HttpClientModule ,
        MatSelectModule,
        MatDialogModule
         
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent, 
        AppComponent,
        AdminLayoutComponent, 
        LoginComponent  
    ],
    providers: [NotificationsComponent,{provide: MAT_DATE_LOCALE, useValue: 'fr-FR'}
    ,authInterceptorProviders
  /*,{ provide: LOCALE_ID, useValue: 'ar' }*/],
    bootstrap:    [ AppComponent ]
})

export class AppModule { }
