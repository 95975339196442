import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { Observable } from 'rxjs';
//custom imports  
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TokenStorageService } from 'app/pages/_services/token-storage.service';
import { HttpService } from 'app/pages/_services/http.service';
//import {fir} from 'firebase';
//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
/*export  var ROUTES: RouteInfo[] = 
 */ 
//    [{ path: '/business', title: 'business', type: 'link', icontype: 'nc-icon nc-bullet-list-67' }];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})
export class SidebarComponent {
    public menuItems: any[];
    downloadURL: Observable<String>;
    uid: String;
    userName: String;
    photo:string;
    notAdmin=true;

    imagePath:SafeResourceUrl;
    showDefaultProfileImage:boolean;

    constructor( private httpService: HttpService,
        private tokenStorage: TokenStorageService ) {
    }

    isNotMobileMenu() {
        if (window.outerWidth > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.getMenus();
        this.userName=this.tokenStorage.getUser().userName;
        this.photo =this.tokenStorage.getUser().photo;
        
      //  this.menuItems = ROUTES.filter(menuItem => menuItem);
        
        var role =this.tokenStorage.getUser().role;
        if(role =='Manager'){
            this.notAdmin=false;
        }
    }

    getMenus(){
        this.httpService.get('/bck/menus?idprof='+this.tokenStorage.getUser().authProfile).subscribe(
            data => { 
                              //  this.menuItems=  [{ path: '/business', title: 'business', type: 'link', icontype: 'nc-icon nc-bullet-list-67' }];//.join(data);
                                this.menuItems=data;
                console.log(this.menuItems)
            } 
          );
    }
    ngAfterViewInit() {
    }
    
}
